import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { dayjs } from "@/lib/dayjs";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatDateDiff = (date: Date) => {
  const now = dayjs();
  const diffMS = now.diff(date);
  if (diffMS < 60 * 60 * 1000) {
    // within 60 min
    return `${now.diff(date, "m")}分前`;
  }
  if (diffMS < 24 * 60 * 60 * 1000) {
    // within 24 hours
    return `${now.diff(date, "h")}時間前`;
  }
  return `${now.diff(date, "d")}日前`;
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
