import "src/styles/globals.css";
import { Toaster } from "@/components/ui/toaster";
import { ApolloProvider, type NormalizedCacheObject } from "@apollo/client";
import { Analytics } from "@vercel/analytics/react";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import { Inter, M_PLUS_Rounded_1c, Noto_Sans_JP } from "next/font/google";
import React from "react";
import { APOLLO_STATE_PROP_NAME, useApollo } from "src/lib/apollo/client";
import { GoogleTagManager, usePageView } from "src/lib/google-analytics";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
  weight: ["300", "400", "500", "700"],
});

const notoSansJP = Noto_Sans_JP({
  subsets: ["latin"],
  variable: "--font-noto-sans-jp",
  weight: ["300", "400", "500", "700"],
});

const mPlusRounded1C = M_PLUS_Rounded_1c({
  subsets: ["latin"],
  variable: "--font-m-plus-rounded-1c",
  weight: ["300", "400", "500", "700"],
  display: "swap",
});

type Props = {
  [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject;
  session: Session;
};

function MyApp({ Component, pageProps }: AppProps<Props>) {
  const apolloClient = useApollo(pageProps.__APOLLO_STATE__);
  usePageView();

  return (
    <>
      <SessionProvider session={pageProps.session}>
        <ApolloProvider client={apolloClient}>
          <GoogleTagManager />
          <Toaster />
          <DefaultSeo title="askmate" />
          <style jsx global>{`
            :root {
              --font-inter: ${inter.style.fontFamily};
              --font-noto-sans-jp: ${notoSansJP.style.fontFamily};
              --font-m-plus-rounded-1c: ${mPlusRounded1C.style.fontFamily};
            }
          `}</style>
          <Component {...pageProps} />
        </ApolloProvider>
      </SessionProvider>
      <Analytics />
    </>
  );
}
export default MyApp;
